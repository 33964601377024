import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import s from './styles.module.scss';
import Form from '../Form';
import { useSelector } from 'react-redux';

function Roles({
	isNew,
	data,
	header,
	setIsNew,
	setShowMessage,
	user,
	isModified,
	setIsModified
}) {
	const [formData, setFormData] = useState(null);
	const userAlias = user?.user?.userList?.userAlias;
	const subDimensionID = user?.user?.userList?.subsDimensionID;
	const upstream = useSelector((state) => state.upstream);
	const chosenUpstream = upstream?.upstreams.find(
		(item) => item.selected == true
	).upstreamName;

	useEffect(() => {
		const formData = [];
		if (data) {
			_.forOwn(data, (val, key) => {
				formData.push({
					name:
					(chosenUpstream == 'SAP' && val.isOOW == true) 
							? 'OOW'
							: chosenUpstream == 'SAP'
							? 'SAP' 
							: chosenUpstream == 'MCS' 
							? (val.isSCB == false ? 'MCS' : 'SCB')
							: 'Pre',
					prefix: val.prefix,
					from: val.rangeFrom,
					to: val.rangeTo,
					subsDimensionID: subDimensionID,
					taxFlag: true,
					guiYear: val.guiYear,
					userName: userAlias,
					guiSegmentRowNum: val.guiSegmentRowNum,
					isOOW: val.isOOW,
					isSCB: val.isSCB
				});
			});
		}
		setFormData(formData);
	}, [data]);

	return (
		<div className={s.root}>
			{formData &&
				formData.length > 0 &&
				formData.map((x, ind) => (
					<Form
						isNew={isNew}
						key={ind}
						formData={x}
						header={header}
						setIsNew={setIsNew}
						setShowMessage={setShowMessage}
						isModified={isModified}
						setIsModified={setIsModified}
					/>
				))}
		</div>
	);
}

export default Roles;
